.App {
  display: grid;
  grid-template-columns: 0.5fr 2.5fr;
  width: 100vw;
  height: 100vh;
  background: linear-gradient(90deg, rgb(38, 38, 38), rgb(143, 141, 141));
  color: bisque;
  overflow-y: auto;
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  .App {
    display: flex;
  }
}
@media only screen and (min-width: 577px) and (max-width: 768px) {
  .App {
    display: flex;
  }
}
@media only screen and (min-width: 769px) and (max-width: 992px) {
  .App {
    display: flex;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .App {
    display: flex;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1800px) {
  .App {
    display: flex;
  }
}