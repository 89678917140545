.burger-menu {
  display: none;
  position: fixed;
  top: 20px;
  left: 20px; 
  width: 30px;
  height: 30px;
  cursor: pointer;
  z-index: 999;
  display: none;
}

.linkButton {
  text-decoration: none;
}

.burger-menu-line {
  width: 100%;
  height: 4px;
  background-color: #fff;
  margin-bottom: 5px;
  transition: all 0.3s ease;
}

.burger-menu.open .burger-menu-line:nth-child(1) {
  transform: rotate(45deg) translate(5px, 5px);
}

.burger-menu.open .burger-menu-line:nth-child(2) {
  opacity: 0;
}

.burger-menu.open .burger-menu-line:nth-child(3) {
  transform: rotate(-45deg) translate(5px, -5px);
}

.burger-menu-links {
  display: none; 
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  width: 100%; 
}

.burger-menu-links.open { 
  display: flex;
}

.burger-menu-link {
  margin: 10px;
  padding: 10px 20px;
  background-color: transparent;
  border: 1px solid rgb(92, 91, 91);
  border-radius: 20px;
  cursor: pointer;
  color: bisque;
  text-decoration: none;
  font-size: 1.5em;
  transition: transform 0.3s ease;
}

.burger-menu-link:hover {
  transform: scale(1.1); 
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
  .burger-menu {
    display: block;
  }
}
@media only screen and (min-width: 577px) and (max-width: 768px) {
  .burger-menu {
    display: block;
  }
}
@media only screen and (min-width: 769px) and (max-width: 992px) {
  .burger-menu {
    display: block;
  }
}
@media only screen and (min-width: 993px) and (max-width: 1200px) {
  .burger-menu {
    display: block;
  }
}
@media only screen and (min-width: 1201px) and (max-width: 1800px) {

}