.page {
  width: 100%;
  padding: 0 20px;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.container {
  margin: 30px 16px;
}

.image {
  position: absolute;
  top: 0;
  right: 4%;
  height: 100%;
  width: auto;
  z-index: 1;
}

.videoPlayer {
  width: 100%;
  max-width: 100%;
  margin-top: 20px;
  position: relative;
  padding-bottom: 56.25%;
  overflow: hidden;
}

.videoPlayer iframe,
.videoPlayer video {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.videoGridContainer {
  height: 300px;
  overflow-y: auto;
}

.videoGrid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  gap: 20px;
  justify-items: center;
  margin-bottom: 20px;
}

.videoCard {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 8px;
  cursor: pointer;
}

.videoCard img {
  width: 100%;
  height: auto;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.videoTitle {
  margin-top: 5px;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
}

.videoLink {
  margin-top: 5px;
  text-decoration: underline;
  color: #ebe5e5;
}

@media only screen and (min-width: 320px) and (max-width: 576px),
  only screen and (min-width: 577px) and (max-width: 768px),
  only screen and (min-width: 769px) and (max-width: 992px),
  only screen and (min-width: 993px) and (max-width: 1200px) {
  .page {
    margin-top: 50px;
    margin-left: 20px;
    margin-right: 20px;
  }
  .image {
    display: none;
  }
  .videoGridContainer {
    height: auto;
  }
}

.videoGridContainer::-webkit-scrollbar {
  width: 10px;
}

.videoGridContainer::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.5);
  border-radius: 5px;
}

.videoGridContainer::-webkit-scrollbar-track {
  background-color: transparent;
}

