* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

#root {
    background-color: white;
    width: 100%;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    min-height: 100vh;
}