.myButton {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 36px;
  margin-top: 50px;
  width: 200px;
  height: 50px;
  color: bisque;
  text-decoration: none;
  font-size: 1.5em;
  border: 1px solid rgb(92, 91, 91);
  background: linear-gradient(45deg, rgb(38, 38, 38), rgb(92, 91, 91));
  border-radius: 20px;
  cursor: pointer;
  transition: transform 0.3s ease;
}

.myButton:hover {
  transform: scale(1.3);
}
