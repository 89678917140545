.page {
    width: 100%;
    padding: 0 20px;
    position: relative;
}

.image {
    position: absolute;
    top: 0;
    right: 4%;
    height: 100%;
    width: auto;
    z-index: 1;
}

.dancing-script {
    text-align: center;
    font-family: 'PT Serif', serif;
    font-weight: 700;
    font-style: italic;
    font-size: 48px;
    z-index: 2;
    position: relative;
}

@media only screen and (min-width: 320px) and (max-width: 576px) {
    .page {
        margin-top: 50px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .image {
        display: none;
    }
}
@media only screen and (min-width: 577px) and (max-width: 768px) {
    .page {
        margin-top: 50px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .image {
        display: none;
    }
}
@media only screen and (min-width: 769px) and (max-width: 992px) {
    .page {
        margin-top: 50px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .image {
        display: none;
    }
}
@media only screen and (min-width: 993px) and (max-width: 1200px) {
    .page {
        margin-top: 50px;
        margin-left: 20px;
        margin-right: 20px;
    }
    .image {
        display: none;
    }
}
@media only screen and (min-width: 1201px) and (max-width: 1800px) {
}